import React from "react";
import { Link } from "react-router-dom";

export default function CompanyProfileCard({ company, loading }) {
  return (
    <div className="shadow rounded-md w-full p-6 flex">
      <div className="hidden sm:block w-1/6 mr-6">
        <img src={company.logo} alt={company.id} />
      </div>
      <div className="w-full sm:w-5/6 flex flex-col">
        <div className="mx-auto sm:hidden max-w-xs pb-6">
          <img src={company.logo} alt={company.id} />
        </div>
        <div className="flex justify-between gap-4">
          <div className="flex flex-col">
            <div className="text-2xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-3xl sm:leading-9 md:text-4xl md:leading-10">
              {company.name}
            </div>

            <div className="sm:hidden mt-4 text-xl text-gray-900">
              {company.country}
            </div>
            <div className="sm:hidden text-gray-500">
              {company.headquarter_city}
            </div>

            <div className="leading-6 text-teal-500 mb-6">
              {company.industries ?? ""}
            </div>
          </div>
          <div className="flex flex-col hidden sm:block">
            <div className="text-right text-gray-900 text-xl">
              {company.country}
            </div>
            <div className="text-right text-gray-500">
              {company.headquarter_city}
            </div>
          </div>
        </div>
        <div className="text-gray-500">{company.description}</div>

        <div className="flex flex-col mt-12 pt-12 border-t border-gray-200">
          <div className="flex mb-3">
            <div className="w-1/2 md:w-1/6 sm:mr-6 font-extrabold">
              Staff Size
            </div>
            <div className="w-1/2 text-gray-500">
              {company.staff_count_range_start}
              {" ~ "}
              {company.staff_count_range_end}
            </div>
          </div>
          <div className="flex mb-3">
            <div className="w-1/2 md:w-1/6 sm:mr-6 font-extrabold">Website</div>
            <div className="w-1/2 text-gray-500 hover:text-teal-500 break-all">
              <a href={company.website} target="_blank" rel="noreferrer">
                {company.website}
              </a>
            </div>
          </div>
          <div className="flex mb-3">
            <div className="w-1/2 md:w-1/6 sm:mr-6 font-extrabold">Founded</div>
            <div className="w-1/2 text-gray-500">{company.founded_on}</div>
          </div>
          <div className="flex mb-3">
            <div className="w-1/2 md:w-1/6 sm:mr-6 font-extrabold">Type</div>
            <div className="w-1/2 text-gray-500">{company.type}</div>
          </div>
        </div>
        <Link to={`/contact?data_source=${company.id}`}>
          <button
            type="button"
            className="w-full px-4 py-2 mt-4 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none"
          >
            Contact Startupbootcamp to learn more
          </button>
        </Link>
      </div>
    </div>
  );
}
