import Select from "react-select";

export default function DropDownSelect({
  selected,
  setSelected,
  options,
  placeholder,
  setSelectedAlt,
}) {
  const customStyles = {
    option: (styles, { isFocused, isSelected, data }) => {
      return {
        ...styles,
        paddingLeft: "2.5rem",
        backgroundColor: isSelected ? "#0694a2" : isFocused ? "#16bdca" : null,
        color: isSelected ? "white" : isFocused ? "white" : "black",
        fontStyle: data.region ? "italic" : "none",
        ":active": {
          backgroundColor: "#16bdca",
          color: "white",
        },
      };
    },
    placeholder: (styles) => ({ ...styles, color: "#97a6ba" }),
    control: (base) => ({
      ...base,
      height: "42px",
      lineHeight: "1.25rem",
      fontSize: "0.875rem",
      border: 0,
      boxShadow: "none",
      paddingLeft: "2rem",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      const fontStyle = state.data.region ? "italic" : "none";

      return { ...provided, opacity, transition, fontStyle };
    },
  };

  function handleSelect(val) {
    if (!val) {
      // null value, reset both
      setSelectedAlt && setSelectedAlt(null);
      setSelected(null);
      return;
    }

    // If region is selected, country should be reset
    // vice versa, otherwise you end up with ?region=Oceania&country=USA
    if (val.region) {
      setSelectedAlt && setSelectedAlt(val);
      setSelected(null);
    } else {
      setSelectedAlt && setSelectedAlt(null);
      setSelected(val.value);
    }
  }

  return (
    <Select
      className="w-full lg:w-4/5  rounded border shadow"
      placeholder={placeholder}
      defaultValue={selected}
      onChange={handleSelect}
      options={options}
      isSearchable={true}
      isClearable={true}
      styles={customStyles}
    />
  );
}
