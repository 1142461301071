import React, { useCallback, useEffect, useState } from "react";
import SearchBar from "../components/SearchBar";
import CompanyListTile from "../components/CompanyListTile.js";
import { useLocation } from "react-router-dom";
import { useInfiniteQuery } from "react-query";
import * as api from "../services/api";

export default function Explore() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const query = params.get("search") || "";
  const region = params.get("region") || null;
  const country = params.get("country") || null;
  const size = params.get("size") || null;
  const [resultsCount, setResultsCount] = useState(null);
  const [showSearchbar, setShowSearchbar] = useState(true);
  const { data, isLoading, fetchNextPage, hasNextPage, isFetching } =
    useInfiniteQuery(
      [query, { region, country, size }],
      ({ pageParam }) =>
        api.getCompanies({
          query,
          region,
          country,
          resultsCount,
          size,
          pageParam,
        }),
      {
        retry: 0,
        staleTime: Infinity,
        getNextPageParam: (lastPage, _pages) => lastPage.after,
      }
    );

  const toggleSearchbar = () => {
    const toggle = !showSearchbar;
    setShowSearchbar(toggle);
    localStorage.setItem("searchbar", toggle);
  };

  const onScroll = useCallback(() => {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;

    if (hasNextPage && bottom) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [onScroll]);

  useEffect(() => {
    const visibleStorage = localStorage.getItem("searchbar");
    const visible = visibleStorage === "false" ? false : true;
    setShowSearchbar(visible);
  }, []);

  useEffect(() => {
    if (!data) return;
    setResultsCount(data.pages[0].count);
  }, [data]);

  const res = data?.pages.reduce((acc, page) => acc.concat(page.data), []);

  return (
    <>
      <div className="company-search-bar py-4 z-10 bg-white sticky">
        <button
          className="z-10 absolute text-2xl text-black top-4 right-4 focus:outline-none"
          onClick={toggleSearchbar}
        >
          {showSearchbar ? "-" : "+"}
        </button>
        {showSearchbar ? (
          <SearchBar
            query={query}
            region={
              region && {
                value: region,
                label: `All of ${region}`,
                region: true,
              }
            }
            country={country && { value: country, label: country }}
            size={size && { value: size, label: size.replace(",", " ~ ") }}
            resultsCount={resultsCount && resultsCount}
            setResultsCount={setResultsCount}
          />
        ) : (
          <div></div>
        )}
      </div>

      {(isLoading || isFetching) && <div className="progress-loader"></div>}

      <div className="max-w-screen-xl px-1 py-6 mx-auto sm:px-8 lg:px-10 lg:py-24">
        {res &&
          res.map((company) => (
            <CompanyListTile
              key={company.data.id}
              company={company.data}
            ></CompanyListTile>
          ))}

        {!isLoading && !hasNextPage && (
          <div className="text-center leading-8 tracking-tight p-6">
            {res?.length ? "No more results" : "No results"}
          </div>
        )}
      </div>
    </>
  );
}
