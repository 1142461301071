import React from "react";
import { Link } from "react-router-dom";

export default function CompanyListTile({ company }) {
  return (
    <Link
      to={`/company/${company.id}`}
      style={{ textDecoration: "none", color: "black" }}
      onClick={() => {
        localStorage.setItem("ypos", window.pageYOffset);
      }}
    >
      <div className="flex h-48 sm:h-44 lg:h-40 shadow mb-6 rounded overflow-hidden p-4 gap-4">
        <div className="w-1/6 flex justify-center items-center">
          {company.logo && (
            <img
              className="object-contain h-12 sm:h-24"
              src={company.logo}
              alt={company.id}
            />
          )}
        </div>
        <div className="flex flex-col w-5/6 justify-center">
          <div className="text-lg sm:text-2xl font-extrabold">
            {company.name}
          </div>
          <div className="text-sm sm:text-base text-teal-500 mb-0 sm:mb-4">
            {company.industries}
          </div>
          <div className="text-sm sm:text-base text-gray-800 overflow-hidden">
            {company.tagline}
          </div>
        </div>
      </div>
    </Link>
  );
}
