import React, { useState } from "react";
import { useHistory } from "react-router";
import { countriesGroup, staffSizes } from "../services/dropdowns";
import DropDownSelect from "./DropDownSelect";

export default function SearchBar({
  query = "",
  region = null,
  country = null,
  size = null,
  resultsCount = 0,
  setResultsCount,
}) {
  const history = useHistory();
  const [textInput, setTextInput] = useState(query);

  const [regionSelect, setRegionSelect] = useState(region);
  const [countrySelect, setCountrySelect] = useState(country);
  const [sizeSelect, setSizeSelect] = useState(size);

  const onClick = () => {
    const params = [];
    const selects = [
      { param: "search", val: textInput },
      { param: "region", val: regionSelect },
      { param: "country", val: countrySelect },
      { param: "size", val: sizeSelect },
    ];

    for (let s of selects) {
      if (s.val)
        params.push(`${s.param}=${encodeURIComponent(s.val?.value ?? s.val)}`);
    }
    if (typeof setResultsCount === "function") setResultsCount(null);
    const pathParams = params.length ? `?${params.join("&")}` : "";
    history.push(`/explore${pathParams}`);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onClick();
    }
  };

  return (
    // https://www.w3schools.com/howto/howto_js_navbar_hide_scroll.asp
    // implement this to hide the searchbar when scrolling down on mobile

    // https://stackoverflow.com/questions/59982018/how-do-i-get-tailwinds-active-breakpoint-in-javascript
    <div className="shadow p-4 pt-8 rounded-md relative">
      <div className="w-full mt-4 gap-4 flex flex-wrap lg:flex-nowrap justify-end">
        <input
          className="shadow pl-10 w-full  py-3 border rounded-md leading-5 placeholder-gray-400 focus:outline-none sm:text-sm"
          placeholder="Search"
          value={textInput}
          onChange={(e) => setTextInput(e.target.value)}
          onKeyDown={handleKeyPress}
        />

        <DropDownSelect
          placeholder="Country"
          selected={countrySelect || regionSelect}
          setSelected={setCountrySelect}
          setSelectedAlt={setRegionSelect}
          options={countriesGroup}
        />
        <DropDownSelect
          placeholder="Staff Size"
          selected={sizeSelect}
          setSelected={setSizeSelect}
          options={staffSizes}
        />
        <button
          className="shadow px-3 py-3 rounded-md leading-5 bg-teal-500 text-white focus:outline-none focus:bg-teal-400 sm:text-sm transition duration-150 ease-in-out"
          onClick={onClick}
        >
          Search
        </button>
      </div>
      <div className="pt-4 text-right h-10">
        {!!resultsCount && (
          <>
            <span className="text-teal-500">{resultsCount}</span>
            {" result(s)"}
          </>
        )}
      </div>
    </div>
  );
}
