import { client, q } from "./faunadb";

const PAGE_SIZE = 10;
const COLLECTION_NAME = "companies";
const DESCRIPTION_BY_WORDS_INDEX = "description_by_words";
const COMPANY_NAME_BY_WORDS_INDEX = "name_by_words";
const REGION_INDEX = "region";
const COUNTRY_INDEX = "country";
const COMPANY_SIZE_INDEX = "size";
const FEATURED_COMPANIES_INDEX = "featured";

const COMPANY_ID_INDEX = "id";

export const getCompanies = (queryParams) => {
  const { query, region, country, size, pageParam, resultsCount } = queryParams;

  const matches = [];
  const paginateOptions = { size: PAGE_SIZE };

  if (pageParam) {
    // If fetching next page, pageParam will hold the reference
    paginateOptions["after"] = [
      q.Ref(pageParam[0].value.collection, pageParam[0].value.id),
    ];
  }

  if (query) {
    // Split the query up into words
    // Search both the description and company name for matches
    const strings = query.split(" ").reduce((arr, curr) => {
      if (curr !== "") arr.push(curr.toLowerCase());
      return arr;
    }, []);
    for (let s of strings) {
      matches.push(
        q.Union(
          q.Match(q.Index(DESCRIPTION_BY_WORDS_INDEX), s),
          q.Match(q.Index(COMPANY_NAME_BY_WORDS_INDEX), s)
        )
      );
    }
  }

  if (region) {
    matches.push(q.Match(q.Index(REGION_INDEX), region));
  }
  if (country) {
    matches.push(q.Match(q.Index(COUNTRY_INDEX), country));
  }

  if (size) {
    const sizeArr = size.split(",").map((x) => {
      if (x === "") {
        return null;
      }
      return parseInt(x);
    });
    matches.push(q.Match(q.Index(COMPANY_SIZE_INDEX), sizeArr));
  }

  // Default index since Intersection requires at least one argument
  matches.push(q.Documents(q.Collection(COLLECTION_NAME)));

  const getCount = resultsCount === null ? true : false;
  return new Promise((resolve, reject) => {
    client
      .query(
        q.Let(
          {
            count: q.If(getCount, q.Count(q.Intersection(...matches)), null),
            data: q.Map(
              q.Paginate(q.Intersection(...matches), {
                ...paginateOptions,
              }),
              q.Lambda("ref", q.Get(q.Var("ref")))
            ),
          },
          {
            count: q.If(getCount, q.Var("count"), resultsCount),
            after: q.If(
              q.ContainsField("after", q.Var("data")),
              q.Select(["after"], q.Var("data")),
              null
            ),
            data: q.Select(["data"], q.Var("data")),
          }
        )
      )
      .then((res) => resolve(res))
      .catch((error) => reject(error));
  });
};

export const getCompanyById = ({ queryKey }) => {
  const [companyId] = queryKey;
  return new Promise((resolve, reject) => {
    client
      .query(q.Get(q.Match(q.Index(COMPANY_ID_INDEX), companyId)))
      .then((res) => resolve(res))
      .catch((error) => reject(error));
  });
};

export const getFeaturedCompanies = () => {
  return new Promise((resolve, reject) => {
    client
      .query(
        q.Map(
          q.Paginate(q.Match(q.Index(FEATURED_COMPANIES_INDEX), true), {
            size: 3,
          }),
          q.Lambda("x", q.Get(q.Var("x")))
        )
      )
      .then((res) => resolve(res))
      .catch((error) => reject(error));
  });
};
