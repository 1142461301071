import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export default function ContactUsEmbed() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const goBack = () => {
    history.goBack();
  };

  const hubspotSetup = () => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    script.async = true;

    document.body.appendChild(script);

    script.addEventListener("load", () => {
      setLoading(false);
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "8921586",
          formId: "f61aa74f-ac45-488d-b05e-36878a7aca39",
          target: "#hubspotForm",
        });
      }
    });
  };

  useEffect(() => {
    setTimeout(() => window.scrollTo({ top: 0 }), 100);
    setLoading(true);
    hubspotSetup();
  }, []);

  return (
    <div>
      {" "}
      <div
        className="flex items-center pb-6 text-sm font-semibold text-teal-500 hover:text-teal-400  cursor-pointer"
        onClick={goBack}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-4 h-4"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
        <span className="ml-1">Return</span>
      </div>
      {loading && <div className="progress-loader"></div>}
      <div className="shadow rounded-md p-4">
        <div id="hubspotForm"></div>
      </div>
    </div>
  );
}
