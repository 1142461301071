export const regions = [
  { value: "Oceania", label: "Oceania" },
  { value: "Europe", label: "Europe" },
  { value: "Americas", label: "Americas" },
  { value: "Asia", label: "Asia" },
  { value: "Africa", label: "Africa" },
];

export const countriesGroup = [
  {
    label: "Oceania",
    options: [
      { value: "Oceania", label: "All of Oceania", region: true },
      { value: "Australia", label: "Australia" },
      { value: "New Zealand", label: "New Zealand" },
    ],
  },
  {
    label: "Europe",
    options: [
      { value: "Europe", label: "All of Europe", region: true },

      { value: "Austria", label: "Austria" },
      { value: "Belgium", label: "Belgium" },
      { value: "Czech Republic", label: "Czech Republic" },
      { value: "Denmark", label: "Denmark" },
      { value: "Estonia", label: "Estonia" },
      { value: "Finland", label: "Finland" },
      { value: "France", label: "France" },
      { value: "Germany", label: "Germany" },
      { value: "Greece", label: "Greece" },
      { value: "Ireland", label: "Ireland" },
      { value: "Italy", label: "Italy" },
      { value: "Luxembourg", label: "Luxembourg" },
      { value: "Netherlands", label: "Netherlands" },
      { value: "Norway", label: "Norway" },
      { value: "Portugal", label: "Portugal" },
      { value: "Slovakia", label: "Slovakia" },
      { value: "Spain", label: "Spain" },
      { value: "Sweden", label: "Sweden" },
      { value: "Switzerland", label: "Switzerland" },
      { value: "Ukraine", label: "Ukraine" },

      { value: "United Kingdom", label: "United Kingdom" },
    ],
  },
  {
    label: "Americas",
    options: [
      { value: "Americas", label: "All of Americas", region: true },
      { value: "Argentina", label: "Argentina" },
      { value: "Brazil", label: "Brazil" },
      { value: "Canada", label: "Canada" },
      { value: "Chile", label: "Chile" },
      { value: "Mexico", label: "Mexico" },
      { value: "Peru", label: "Peru" },
      { value: "United States of America", label: "United States of America" },
    ],
  },
  {
    label: "Asia",
    options: [
      { value: "Asia", label: "All of Asia", region: true },
      { value: "China", label: "China" },
      { value: "Cyprus", label: "Cyprus" },
      { value: "Hong Kong", label: "Hong Kong" },
      { value: "India", label: "India" },
      {
        value: "Iran",
        label: "Iran",
      },
      { value: "Israel", label: "Israel" },
      { value: "Japan", label: "Japan" },
      { value: "Kazakhstan", label: "Kazakhstan" },
      { value: "Malaysia", label: "Malaysia" },
      { value: "Oman", label: "Oman" },
      { value: "Pakistan", label: "Pakistan" },
      { value: "Saudi Arabia", label: "Saudi Arabia" },
      { value: "Singapore", label: "Singapore" },
      { value: "South Korea", label: "South Korea" },

      {
        value: "Taiwan",
        label: "Taiwan",
      },
      { value: "Thailand", label: "Thailand" },
      { value: "United Arab Emirates", label: "United Arab Emirates" },
    ],
  },
  {
    label: "Africa",
    options: [
      { value: "Africa", label: "All of Africa", region: true },
      { value: "Egypt", label: "Egypt" },
      { value: "Mauritius", label: "Mauritius" },
      { value: "Morocco", label: "Morocco" },
      { value: "South Africa", label: "South Africa" },
    ],
  },
];

export const industries = [
  { value: "Accounting", label: "Accounting" },
  { value: "Airlines/Aviation", label: "Airlines/Aviation" },
  { value: "Alternative Medicine", label: "Alternative Medicine" },
  { value: "Animation", label: "Animation" },
  { value: "Apparel & Fashion", label: "Apparel & Fashion" },
  { value: "Architecture & Planning", label: "Architecture & Planning" },
  { value: "Arts & Crafts", label: "Arts & Crafts" },
  { value: "Automotive", label: "Automotive" },
  { value: "Aviation & Aerospace", label: "Aviation & Aerospace" },
  { value: "Biotechnology", label: "Biotechnology" },
  { value: "Broadcast Media", label: "Broadcast Media" },
  { value: "Building Materials", label: "Building Materials" },
  {
    value: "Business Supplies & Equipment",
    label: "Business Supplies & Equipment",
  },
  { value: "Capital Markets", label: "Capital Markets" },
  { value: "Chemicals", label: "Chemicals" },
  {
    value: "Civic & Social Organization",
    label: "Civic & Social Organization",
  },
  { value: "Civil Engineering", label: "Civil Engineering" },
  { value: "Commercial Real Estate", label: "Commercial Real Estate" },
  { value: "Computer Hardware", label: "Computer Hardware" },
  { value: "Computer Software", label: "Computer Software" },
  { value: "Construction", label: "Construction" },
  { value: "Consumer Electronics", label: "Consumer Electronics" },
  { value: "Consumer Goods", label: "Consumer Goods" },
  { value: "Consumer Services", label: "Consumer Services" },
  { value: "Cosmetics", label: "Cosmetics" },
  { value: "Defense & Space", label: "Defense & Space" },
  { value: "Design", label: "Design" },
  { value: "Education Management", label: "Education Management" },
  { value: "E-learning", label: "E-learning" },
  {
    value: "Electrical & Electronic Manufacturing",
    label: "Electrical & Electronic Manufacturing",
  },
  { value: "Entertainment", label: "Entertainment" },
  { value: "Environmental Services", label: "Environmental Services" },
  { value: "Events Services", label: "Events Services" },
  { value: "Executive Office", label: "Executive Office" },
  { value: "Facilities Services", label: "Facilities Services" },
  { value: "Farming", label: "Farming" },
  { value: "Financial Services", label: "Financial Services" },
  { value: "Fine Art", label: "Fine Art" },
  { value: "Fishery", label: "Fishery" },
  { value: "Food & Beverages", label: "Food & Beverages" },
  { value: "Food Production", label: "Food Production" },
  { value: "Fundraising", label: "Fundraising" },
  { value: "Gambling & Casinos", label: "Gambling & Casinos" },
  { value: "Glass, Ceramics & Concrete", label: "Glass, Ceramics & Concrete" },
  { value: "Government Administration", label: "Government Administration" },
  { value: "Government Relations", label: "Government Relations" },
  { value: "Health, Wellness & Fitness", label: "Health, Wellness & Fitness" },
  { value: "Higher Education", label: "Higher Education" },
  { value: "Hospital & Health Care", label: "Hospital & Health Care" },
  { value: "Hospitality", label: "Hospitality" },
  { value: "Human Resources", label: "Human Resources" },
  { value: "Import & Export", label: "Import & Export" },
  {
    value: "Individual & Family Services",
    label: "Individual & Family Services",
  },
  { value: "Industrial Automation", label: "Industrial Automation" },
  { value: "Information Services", label: "Information Services" },
  {
    value: "Information Technology & Services",
    label: "Information Technology & Services",
  },
  {
    value: "International Trade & Development",
    label: "International Trade & Development",
  },
  { value: "Internet", label: "Internet" },
  { value: "Investment Banking", label: "Investment Banking" },
  { value: "Investment Management", label: "Investment Management" },
  { value: "Law Practice", label: "Law Practice" },
  { value: "Legal Services", label: "Legal Services" },
  { value: "Leisure, Travel & Tourism", label: "Leisure, Travel & Tourism" },
  { value: "Logistics & Supply Chain", label: "Logistics & Supply Chain" },
  { value: "Machinery", label: "Machinery" },
  { value: "Management Consulting", label: "Management Consulting" },
  { value: "Maritime", label: "Maritime" },
  { value: "Market Research", label: "Market Research" },
  { value: "Marketing & Advertising", label: "Marketing & Advertising" },
  {
    value: "Mechanical Or Industrial Engineering",
    label: "Mechanical Or Industrial Engineering",
  },
  { value: "Media Production", label: "Media Production" },
  { value: "Medical Device", label: "Medical Device" },
  { value: "Medical Practice", label: "Medical Practice" },
  { value: "Military", label: "Military" },
  { value: "Mining & Metals", label: "Mining & Metals" },
  { value: "Music", label: "Music" },
  { value: "Nanotechnology", label: "Nanotechnology" },
  { value: "Newspapers", label: "Newspapers" },
  {
    value: "Non-profit Organization Management",
    label: "Non-profit Organization Management",
  },
  { value: "Oil & Energy", label: "Oil & Energy" },
  { value: "Online Media", label: "Online Media" },
  { value: "Outsourcing/Offshoring", label: "Outsourcing/Offshoring" },
  { value: "Package/Freight Delivery", label: "Package/Freight Delivery" },
  { value: "Packaging & Containers", label: "Packaging & Containers" },
  { value: "Paper & Forest Products", label: "Paper & Forest Products" },
  { value: "Pharmaceuticals", label: "Pharmaceuticals" },
  { value: "Photography", label: "Photography" },
  { value: "Plastics", label: "Plastics" },
  {
    value: "Professional Training & Coaching",
    label: "Professional Training & Coaching",
  },
  { value: "Program Development", label: "Program Development" },
  { value: "Public Policy", label: "Public Policy" },
  {
    value: "Public Relations & Communications",
    label: "Public Relations & Communications",
  },
  { value: "Public Safety", label: "Public Safety" },
  { value: "Publishing", label: "Publishing" },
  { value: "Real Estate", label: "Real Estate" },
  {
    value: "Recreational Facilities & Services",
    label: "Recreational Facilities & Services",
  },
  { value: "Religious Institutions", label: "Religious Institutions" },
  { value: "Renewables & Environment", label: "Renewables & Environment" },
  { value: "Research", label: "Research" },
  { value: "Retail", label: "Retail" },
  { value: "Security & Investigations", label: "Security & Investigations" },
  { value: "Semiconductors", label: "Semiconductors" },
  { value: "Shipbuilding", label: "Shipbuilding" },
  { value: "Sports", label: "Sports" },
  { value: "Staffing & Recruiting", label: "Staffing & Recruiting" },
  { value: "Telecommunications", label: "Telecommunications" },
  { value: "Textiles", label: "Textiles" },
  { value: "Think Tanks", label: "Think Tanks" },
  { value: "Translation & Localization", label: "Translation & Localization" },
  {
    value: "Transportation/Trucking/Railroad",
    label: "Transportation/Trucking/Railroad",
  },
  { value: "Utilities", label: "Utilities" },
  {
    value: "Venture Capital & Private Equity",
    label: "Venture Capital & Private Equity",
  },
  { value: "Veterinary", label: "Veterinary" },
  { value: "Warehousing", label: "Warehousing" },
  { value: "Wholesale", label: "Wholesale" },
  { value: "Wine & Spirits", label: "Wine & Spirits" },
  { value: "Writing & Editing", label: "Writing & Editing" },
];

export const staffSizes = [
  { value: [null, 1], label: "~ 1" },
  { value: [2, 10], label: "2 ~ 10" },
  { value: [11, 50], label: "11 ~ 50" },
  { value: [51, 200], label: "51 ~ 200" },
  { value: [201, 500], label: "201 ~ 500" },
  { value: [501, 1000], label: "501 ~ 1000" },
  { value: [1001, 5000], label: "1001 ~ 5000" },
  { value: [5001, 10000], label: "5001 ~ 10000" },
  { value: [10001, null], label: "10001 ~" },
];
