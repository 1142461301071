import React from "react";
import { Link } from "react-router-dom";

export default function Featured({ companies }) {
  return (
    <div className="mt-6 sm:mt-8 lg:mt-16">
      <h1 className="font-extrabold text-2xl text-gray-800">
        Featured companies
      </h1>

      <ul className="mt-4 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 sm:gap-x-6 md:grid-cols-3  xl:gap-x-8">
        {companies.map(({ data: company }) => (
          <Link key={company.id} to={`company/${company.id}`}>
            <li className="relative shadow p-4 rounded-md">
              <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg overflow-hidden">
                <img
                  src={company.logo}
                  alt=""
                  className="mx-auto object-contain pointer-events-none group-hover:opacity-75"
                />
              </div>
              <p className="mt-2 block text-base font-medium text-gray-900 truncate pointer-events-none">
                {company.name}
              </p>
              <p className="block text-sm font-medium text-teal-500 pointer-events-none">
                {company.industries}
              </p>
            </li>
          </Link>
        ))}
      </ul>
    </div>
  );
}
