import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AppBar from "./components/AppBar";
import Home from "./pages/Home";
import CompanyProfile from "./pages/CompanyProfile";
import Explore from "./pages/Explore";
import Footer from "./components/Footer";
import ContactUsEmbed from "./pages/ContactUsEmbed";
import SignUpEmbed from "./pages/SignUpEmbed";
function App() {
  return (
    <>
      <Router>
        <AppBar />
        <div className="container max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8 lg:py-24">
          <Switch>
            <Route path="/signup">
              <SignUpEmbed />
            </Route>
            <Route path="/contact">
              <ContactUsEmbed />
            </Route>
            <Route path="/explore">
              <Explore />
            </Route>
            <Route path="/company/:companyId">
              <CompanyProfile />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
        <Footer />
      </Router>
    </>
  );
}

export default App;
