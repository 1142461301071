import React from "react";
import { Link } from "react-router-dom";
export default function ButtonAppBar() {
  return (
    <div className="py-6 bg-gray-900 sticky top-0 z-10">
      <nav className="relative flex items-center justify-between max-w-screen-2xl px-4 mx-auto sm:px-6">
        <div className="flex items-center flex-1">
          <div className="flex items-center w-full">
            <Link to="/">
              <img
                className="block w-auto h-8 mb-2"
                alt="Startupbootcamp"
                src="https://www.startupbootcamp.com.au/img/logos/sbc-primary-teal-light.svg"
              ></img>
            </Link>

            <div className="hidden space-x-10 md:flex md:ml-10">
              <a
                href="https://www.startupbootcamp.com.au/energy/h2-supercluster"
                alt="startupbootcamp-energy"
              >
                <div className="font-medium text-gray-400 hover:text-gray-500">
                  Greater Geelong Hydrogen Technology Cluster
                </div>
              </a>
            </div>
            <div className="flex-grow"></div>
            <div className="font-medium text-white">
              <Link to="/signup">
                <button className="bg-teal-500 rounded p-3 py-2">
                  Add your company
                </button>
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
