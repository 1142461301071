import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import * as api from "../services/api";
import { useQuery } from "react-query";
import CompanyProfileCard from "../components/CompanyProfileCard";

export default function CompanyProfile() {
  const history = useHistory();
  const { companyId } = useParams();
  const [company, setCompany] = useState({});

  const goBack = () => {
    history.goBack();
    const ypos = localStorage.getItem("ypos") ?? 0;
    setTimeout(() => window.scrollTo({ top: ypos }), 100);
  };

  const { data, isLoading } = useQuery(companyId, api.getCompanyById, {
    staleTime: Infinity,
  });

  useEffect(() => {
    data && setCompany(data.data);
  }, [data]);

  useEffect(() => {
    setTimeout(() => window.scrollTo({ top: 0 }), 100);
  }, []);

  return (
    <>
      <div
        className="flex items-center pb-6 text-sm font-semibold text-teal-500 hover:text-teal-400  cursor-pointer"
        onClick={goBack}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-4 h-4"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
        <span className="ml-1">Return</span>
      </div>
      <div id="progress-loader">
        {isLoading && <div className="progress-loader"></div>}
      </div>
      {company && <CompanyProfileCard company={company} loading={isLoading} />}
    </>
  );
}
