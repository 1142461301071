import React from "react";
import { useQuery } from "react-query";
import Featured from "../components/Featured";
import SearchBar from "../components/SearchBar";
import * as api from "../services/api";

export default function Home() {
  const { data } = useQuery("featured", api.getFeaturedCompanies, {
    staleTime: Infinity,
  });

  return (
    <div className="py-4">
      <div className="text-center max-w-4xl mx-auto">
        <h1 className="pb-8 text-center text-3xl sm:text-4xl font-extrabold text-gray-900">
          Explore H2 StartupBase
        </h1>
        <p className="text-gray-500 pb-8 ">
          The Greater Geelong Hydrogen Technology Cluster has created a map of
          the global hydrogen network allowing you to search and learn about
          hydrogen organisations and startups. Currently in beta form but please
          take a look!
        </p>
      </div>
      <SearchBar />
      <Featured companies={data?.data ?? []} />
    </div>
  );
}
